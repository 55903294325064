'use strict';
/*
WHEN COMING BACK TO EDIT SOME MORE, ALL SCRIPTS CONTAINS APP.JS AND AS SOON AS YOU SAVE, THEY GET ENCAPSULATED WITH A FUNCTION 
SOLUTION
COPY INDEX.JS TO APP.JS EACH TIME YOU MAKE CHANGE IN INDEX.JS OR CHANGE PATH FROM APP.JS TO INDEX.JS UNTIL DONE WORKING THEN REVERT PATH BACK

THIS ODD BEHAVIOUR WORKS FINE ON AWS APP.JS 

*/
// console.log("Javascript file running...")
 
// Logo when clicked it directs user home and mouse becomes pointer on hover
$('.menu-logo').click(function () {
    // Redirect to the home page

    console.log("clicked logo")
    window.location.href = window.location.origin; 
});

$('.mobile-logo').click(function () {
    // Redirect to the home page
    window.location.href = window.location.origin; 
});

$('.menu-logo').hover(
    function () {
        // Mouse over, set cursor to pointer
        $(this).css('cursor', 'pointer');
    },
    function () {
        // Mouse out, set cursor back to default (optional)
        $(this).css('cursor', 'default');
    }
);


$(document).ready(function () {

    $('#mobile_menu').click(function () {
        // Toggle the visibility of the menu

        $('.desktop-menu-area').toggle("slow");

        // Toggle other elements as needed
        $('.cross-menu').toggle();

        var element = document.getElementById("mobile_menu");
        element.classList.toggle("mobile-menu-class");

        $('.desktop-menu-area').toggleClass('show')

        $('.main-content-area').toggleClass('body-bg');
    });

    $("#hs-form-iframe-0").contents().find(".submitted-message").attr("style","color:#ffffff");
});


window.onload = function(){
    var cnt_us = window.location.href.includes('contactUs')

    if(cnt_us==true){
        goToContactUs('#contactUs')
        var new_url = window.location.href.slice(0,-10);
        window.history.replaceState({},document.title,new_url);
    }
}

//Mobile view contact us button appears if scroll position is less than 300
$(function () {
    $(window).scroll(function () {

        if ($(this).scrollTop() < 300) {
            $(".mobile-menu").removeClass("top-nav");
            $(".mobile-contact-btn-area").removeClass("footer-con-btn");
        } else {
            $(".mobile-menu").addClass("top-nav");
            $(".mobile-contact-btn-area").addClass("footer-con-btn");
        }
    });
});

function moveToElement(elemid, isClickEvent = false){
    
    var element = document?.querySelector(elemid);
    
    $(window).scrollTop($(element).offset()?.top);

    // This line causes the MOBILE drop-down menu to open automatically on page load
    // if(window.innerWidth<1025){
    //     $('.desktop-menu-area').toggle("slow");
    //     $('.cross-menu').toggle();
    //     var element = document.getElementById("mobile_menu");
    //     element.classList.toggle("mobile-menu-class");
    //     $('.desktop-menu-area').toggleClass('show')
    //     $('.main-content-area').toggleClass('body-bg')
    // }
    if(isClickEvent){
    console.log("click movement", isClickEvent)

    $('ul').on('click', 'li', function () {
        // Toggle the visibility of the menu

        $('.desktop-menu-area').toggle("slow");

        // // Toggle other elements as needed
        // $('.cross-menu').remove();
     
        $('.cross-menu').css({
            // Add your styles here
            'display': 'none',
            // Add other styles as needed
        });

        // $('.line-menu').css({
        //     // Add your styles here
        //     'display': 'inline',
        //     // Add other styles as needed
        // });

        var element = document.getElementById("mobile_menu");
        element.classList.remove("mobile-menu-class");

        $('.desktop-menu-area').removeClass('show');
        $('.desktop-menu-area').css({
            // Add your styles here
            'display': 'none',
            // Add other styles as needed
        });

        $('.main-content-area').removeClass('body-bg');
    });

    isClickEvent = false
    }

   
}

window.moveToElement = moveToElement;

function goToContactUs(elem){

    var element = document.querySelector(elem);
    $(window).scrollTop($(element).offset().top);
}

window.goToContactUs = goToContactUs;

/* Auto scroll */
const scrollContainer1 = document.getElementById('clientsContainer1');
const scrollContainer2 = document.getElementById('clientsContainer2');
const clientsScrollWidth1 = scrollContainer1?.scrollWidth;
const clientsScrollWidth2 = scrollContainer2?.scrollWidth;


window.addEventListener('load', () => {
  self.setInterval(() => {
    const first = document.querySelector('#clientsContainer1 .col-4');
    const second = document.querySelector('#clientsContainer2 .col-4');

    if(!isElementInViewport(first)){
        scrollContainer1?.appendChild(first);
        scrollContainer1?.scrollTo(scrollContainer1.scrollLeft - first.offsetWidth, 0);
    }
    if(!isElementInViewport(second)){
        scrollContainer2?.appendChild(second);
        scrollContainer2?.scrollTo(scrollContainer2.scrollLeft - second.offsetWidth, 0);
    }
    if (scrollContainer1?.scrollLeft !== clientsScrollWidth1) {
      scrollContainer1?.scrollTo(scrollContainer1.scrollLeft + 1.5, 0);
    }
    if (scrollContainer2?.scrollLeft !== clientsScrollWidth1) {
        scrollContainer2?.scrollTo(scrollContainer2.scrollLeft + 1.5, 0);
      }
  }, 15);
  
});

function isElementInViewport (el) {
    var rect = el?.getBoundingClientRect();
    return rect?.right > 0;
}

window.isElementInViewport = isElementInViewport;


function readMoreFunction(e) {
    var dots = e.previousElementSibling.children[0]
    var moreText = e.previousElementSibling.children[1]
    //var btnText = document.getElementById("readBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      e.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      e.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
}

window.readMoreFunction = readMoreFunction;

// When clicked it directs to the right html page on the main page menu level
function goToMainPage(pagePath){
    
    window.location.href=`/${pagePath}.html`
}

window.goToMainPage = goToMainPage;

function autoScroll(elementID){

    // Auto scroll coming from a different page does not work. Adding the line will auto scroll for 0.5 each time menu items are clicked to force auto scroll to work on landing page
    window.scrollBy(0, 1);
    // Store the elementID in localStorage
    localStorage.setItem('elementID', elementID);
    
    // Use pushState to update the URL without reloading the page
    history.pushState({ page: 'home' }, null, window.location.origin);

    window.location.reload(); 

}

window.autoScroll = autoScroll;

// Go back home is used in case-studies and library page. 
//When called, this function stores the elementID(if clicked on like in case-studies page or grabbed from URL in library page) in the local storage.
//It uses pushState to update the URL without reloading the page. In this case, it sets the URL to /index.html.
//It then forces a reload of the page (window.location.reload()).
function goBackHome(event, elementID){


    let pagePath = window.location.pathname

    
    if(elementID=="#products" || elementID=="#resources") {
        let parentElement  = event.target.parentNode
    
        if (parentElement.classList.contains("active-item")) {
            parentElement.classList.remove("active-item");
        } else {
            parentElement.classList.add("active-item");
        }
    }
    else if ((elementID == pagePath.replace("/", "#")) || pagePath=="/"){
        moveToElement(elementID, false) 
    }
    else{
        autoScroll(elementID);
    }
    
}

window.goBackHome = goBackHome;

// Call moveToElement after the page is reloaded using goBackHome() coming back to home page. When page is fully loaded, it auto scroll to the clicked section
$(window).on('load', function () {

    console.log("checked here")
    // Auto scroll coming from a different page does not work. Adding the line will auto scroll for 0.5 each time menu items are clicked to force auto scroll to work on landing page
    window.scrollBy(0, 1);
    // Retrieve the elementID from localStorage
    let elementID = localStorage.getItem('elementID');


    if (elementID) {
        console.log("Element found:", elementID);
    } else {
        console.log("Element not found:", elementID);
    }

    // console.log("elementID", elementID)
    if (elementID != '#home') {
        // Pass elementID after the page is reloaded
        moveToElement(elementID, false)
    }


    // Clear the stored elementID 
    localStorage.removeItem('elementID');
});

// Array that contain hashes. These hashes are grabbed from the url of the page window
//The hashes source is from library.html, inside ods-facet-results at <a> tag of each dataset theme e.g. dashboards, ui components , etc
const librarySubPagesArr = [
    "#dashboards",
    "#homepages",
    "#uicomponents",
    "#datastories",
    "#pecletconnect",
]

//This function behaves the same way as the prev func, the only difference is that it grabs the element from the url coming from anywhere
//Then calls goBackHome() which it stores the element in local storage then calls moveToElement() to scroll to that section
//OR if url hash matches a variable/page then do something 
$(document).ready(function() {
            // Check for hash in the URL
            let hash = window.location.hash;

            // console.log("hash" ,hash)

            // If a hash exists, scroll to the corresponding section
            if (hash) {   
                goBackHome(hash);
            }

            //When there's a hash in the url, it checks if pages exists in the Array, if so, run the corresponding func
            if(librarySubPagesArr.includes(hash)){
               
                goToLibrarySubPages(hash);
            }

            //The following two conditions handles "recently added" section in library page. The 'recently added' section generates urls dynamically from ODS
            /***********************************************************************************************************************************************/
            // If the url has '/assets' then add https to the url after removing the hash
           if(hash.includes("/assets")){
                goToODSPage(hash)
           } 
           // If url does have 'https' then remove the hash and keep it as is
           else if(hash.includes("https")){
                removeHashFromNonODSPages(hash)
           }
            
        });
        

// Library page side Nav menu navigation
// function goToLibrarySubPages(pagePath){
//     //format when the args come from the url and not on-click func
//     let formatUrl = pagePath.replace("#","")
 
//     window.location.href=`/library/${formatUrl}.html`
// }

/* For library page 'Recently added' section  */
/***********************************************/
// If the url has '/assets' then add https to the url after removing the hash such as /assets ODS pages
function goToODSPage(odsUrl){
    let formatOdsUrl = odsUrl.replace("#" , "")

    window.location.href = `https://data.peclet.com.au/${formatOdsUrl}`
}

window.goToODSPage = goToODSPage;

// If url does have 'https' then remove the hash and keep it as is for any non internal ODS pages such as councils pages
function removeHashFromNonODSPages(hash){
    let formatExternalUrl = hash.replace("#" , "")
    window.location.href = `${formatExternalUrl}`
}

window.removeHashFromNonODSPages = removeHashFromNonODSPages;

// function goToCaseStudiesSubPages(path){
//     console.log(path)
//     window.location.href=`/case-studies/${path}.html`
   
// }